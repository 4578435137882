import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";

Vue.use(VueRouter)

// 获取原型对象push函数
const originalPush = VueRouter.prototype.push
// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location){
    return originalPush.call(this , location).catch(err=>err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location){
    return originalReplace.call(this , location).catch(err=>err)
}

// 定义基础路由
const routes = [
    {
        path: '/404',
        name: '404',
        component: () => import(/* webpackChunkName: "about" */ '../views/404.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue')
    },
    // 基础路由
    {
        path: '/',
        redirect: '/login'  // 默认重定向到登录页
    }
]

// 重置路由函数
export const resetRouter = () => {
    router.matcher = new VueRouter({
        mode: 'history',
        base: process.env.BASE_URL,
        routes
    })
}

// 初始化路由
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export const setRoutes = () => {
    const storeMenus = sessionStorage.getItem("menus");
    if (storeMenus) {
        // 获取当前的路由对象名称数组
        const currentRouteNames = router.getRoutes().map(v => v.name)
        if (!currentRouteNames.includes('Manage')) {
            // 拼装动态路由
            const manageRoute = { path: '/', name: 'Manage', component: () => import('../views/Manage.vue'), redirect: "/home", children: [
                    { path: 'person', name: '个人信息', component: () => import('../views/within/Person.vue')},
                    { path: 'about', name: '关于', component: () => import('../views/within/About.vue')},
                    { path: 'password', name: '修改密码', component: () => import('../views/within/Password.vue')},
                    { path: 'beimen', name: '北门', component: () => import('../views/within/Beimen.vue')},
                    { path: 'qushuiqiao', name: '曲水桥', component: () => import('../views/within/Qushuiqiao.vue')},
                    { path: 'tushuguan', name: '图书馆', component: () => import('../views/within/Tushuguan.vue')},
                ] }
            const menus = JSON.parse(storeMenus)
            menus.forEach(item => {
                if (item.path) {  // 当且仅当path不为空的时候才去设置路由
                    let itemMenu = { path: item.path.replace("/", ""), name: item.name, component: () => import('../views/' + item.pagePath + '.vue') }
                    manageRoute.children.push(itemMenu)
                } else if(item.children.length) {
                    item.children.forEach(item => {
                        if (item.path) {
                            let itemMenu = { path: item.path.replace("/", ""), name: item.name, component: () => import('../views/' + item.pagePath + '.vue') }
                            manageRoute.children.push(itemMenu)
                        }
                    })
                }
            })
            // 动态添加到现在的路由对象中去
            router.addRoute(manageRoute)
        }
    }
}

setRoutes()

// 路由守卫
router.beforeEach((to, from, next) => {
    sessionStorage.setItem("currentPathName", to.name)
    store.commit("setPath")

    const isLoggedIn = !!localStorage.getItem("menus") // 根据实际登录状态标识调整
    const routeExists = (path) => router.getRoutes().some(r => r.path === path)

    // 未登录特殊处理逻辑
    if (!isLoggedIn) {
        // 允许访问登录页
        if (to.path === '/login') return next()

        // 未登录访问根路径时强制跳转到登录页
        if (to.path === '/') {
            return next('/login')
        }
    }

    // 处理 /home 路径优先级逻辑
    if (to.path === '/home') {
        if (routeExists('/home')) return next()
        if (routeExists('/home2')) return next('/home2')
        return next('/404')
    }

    // 处理未匹配路由（包含无后缀路径）
    if (!to.matched.length) {
        return isLoggedIn ? next('/404') : next('/login') // 未登录时跳转至登录页
    }

    // 正常放行
    next()
})

export default router
